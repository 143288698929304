<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>User</th>
          <th>Name</th>
          <th>File</th>
          <th>Status</th>
          <th>Total contacts</th>
          <th>Max contacts</th>
          <th>Exported contacts</th>
          <th>Description</th>
          <th>Created at</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>
            {{ item.user ? item.user.username : "No user" }}
          </td>
          <td style="min-width: 200px">
            {{ item.name }}
          </td>
          <td>
            <a v-if="item.csv" :href="item.csv">{{
              item.csv.length > 25 ? `${item.csv.slice(0, 30)}...` : item.csv
            }}</a>
          </td>
          <td>
            <v-tooltip bottom v-if="item.status_comment">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  :color="getImportedFileStatusColor(item.status)"
                  class="mr-1"
                >
                  {{ IMPORED_FILE_STATUS[item.status] }}
                </v-chip>
              </template>
              <span>{{ item.status_comment }}</span>
            </v-tooltip>
            <v-chip
              v-else
              x-small
              :color="getImportedFileStatusColor(item.status)"
              class="mr-1"
            >
              {{ IMPORED_FILE_STATUS[item.status] }}
            </v-chip>
          </td>
          <td>
            {{ item.total_contacts }}
          </td>
          <td>
            {{ item.max_contacts }}
          </td>
          <td>
            {{ item.exported_contacts }}
          </td>
          <td>
            <Description :description="item.description" />
          </td>
          <td style="min-width: 200px">
            {{ formatDate(item.created_at) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import {
  renderDateTime,
  IMPORED_FILE_STATUS,
  getImportedFileStatusColor,
} from "@/services/services";
import Description from "./Description.vue";
export default {
  props: ["data"],
  components: {
    Description,
  },
  methods: {
    formatDate(date) {
      return renderDateTime(date);
    },
  },
  computed: {
    getImportedFileStatusColor: () => getImportedFileStatusColor,
    IMPORED_FILE_STATUS: () => IMPORED_FILE_STATUS,
  },
};
</script>
