<template>
  <div
    class="pt-4 pb-4 d-flex align-center"
    v-if="pagesCount && pagesCount > 1"
  >
    <v-pagination
      v-model="page"
      :length="pagesCount"
      :total-visible="5"
      @input="onChangeOffsset"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: ["count", "limit", "offset", "changeOffset"],
  data() {
    return {
      page: 1,
      pagesCount: 1,
    };
  },
  created() {
    this.page =
      Math.ceil(this.offset / this.limit) == 0
        ? 1
        : Math.ceil(this.offset / this.limit) + 1;
    this.pagesCount = Math.ceil(this.count / this.limit);
  },
  methods: {
    onChangeOffsset() {
      if (this.page == 1) {
        this.changeOffset(0);
      } else {
        this.changeOffset(this.page * this.limit - this.limit);
      }
    },
  },
};
</script>

<style></style>
