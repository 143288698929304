<template>
  <div style="width: 180px">
    {{ show_description }}
    <div
      style="color: #1976d2; font-size: 12px; cursor: pointer"
      v-if="description.length > 20"
      @click="full = !full"
    >
      {{ full ? "Hide" : "Show" }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["description"],
  data() {
    return {
      full: false,
    };
  },
  computed: {
    show_description() {
      if (this.description.length > 20) {
        return !this.full
          ? `${this.description.slice(0, 20)}...`
          : this.description;
      } else {
        return this.description;
      }
    },
  },
};
</script>

<style>
</style>