<template>
  <v-card class="ma-3 contact-filter pa-3">
    <div class="contacts-table__header">
      <div class="contacts-table__header-total">
        <b>Exported files</b>
        <div>
          <v-divider
            style="height: 20px; width: 7px; margin: 0 6px"
            inset
            vertical
          ></v-divider>
          <span style="font-size: 13px">Total</span>:
          <b>{{ data ? data.count : 0 }}</b>
          <span
            v-if="data && data.took"
            style="font-size: 12px; color: #b9b9b9"
          >
            <v-divider
              style="height: 20px; width: 7px; margin: 0 6px"
              inset
              vertical
            ></v-divider
            >took: {{ contactsData.took }}ms
          </span>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div>
      <div v-if="data && data.results && data.results.length">
        <Table :data="data.results" />
        <CustomPagination
          :count="data.count"
          :limit="filter.limit"
          :offset="filter.offset"
          :changeOffset="
            (value) => {
              filter.offset = value;
              onGetExportedBroadcasts();
            }
          "
        />
      </div>
      <span
        v-else-if="!is_loading"
        class="d-flex align-center justify-center text-caption"
        >No data found</span
      >
    </div>
  </v-card>
</template>

<script>
import { getExportBroadcast } from "@/store/modules/broadcasts/api";
import Table from "./components/Table.vue";
import CustomPagination from "@/components/CustomPagination.vue";
import {
  objectFromQueryString,
  changeUrlQuery,
  requestStatus,
  buildFilterString,
} from "@/services/services";
export default {
  components: {
    Table,
    CustomPagination,
  },
  data() {
    return {
      is_loading: false,
      data: null,
      filter: {
        limit: 30,
        offset: 0,
      },
    };
  },
  created() {
    let queryFilter = objectFromQueryString();
    if (queryFilter.limit) this.filter.limit = queryFilter.limit;
    if (queryFilter.offset) this.filter.offset = queryFilter.offset;
    this.onGetExportedBroadcasts();
  },
  methods: {
    onGetExportedBroadcasts() {
      changeUrlQuery(this.filter);
      this.is_loading = true;
      getExportBroadcast(buildFilterString(this.filter))
        .then((response) => {
          if (response.status === requestStatus.success) {
            // console.log(response.response.data);
            this.data = response.response.data;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style lang="scss">
.contacts-table {
  &__header {
    display: flex;
    align-items: center;
    &-total {
      display: flex;
      align-items: center;
    }
  }
}
</style>
